// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import ReactLoading from "react-loading";
import DropZone from 'react-dropzone'
import "../../styles/claimID.css";

import DragAndDrop from '../subComponents/DragAndDrop';
import Checkbox from '../subComponents/Checkbox';

import api from "../../api";

import PlanCard from '../subComponents/PlanCard';

import { fetchClaimById, fetchPlanById, updateEmployee, fetchEmployeeById, fetchEmployerById, sendReceipt, updateClaim } from '../../helpers/dataHandler';
import { promptForDelete } from '../../helpers/helperFunctions';

import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// import { FilePicker } from "react-file-picker";

export default class Claim extends React.Component {

    state = {
        claim: null,
        employee: null,
        employer: null,
        plan: null,

        editsMade: false,
        editingFields: {
            employeeName: '',
            employeeEmail: '',
            claimFor: [],
        },

        dataFieldsHoverIdx: -1,
        dataFields: {},
        newDataField: {
            key: '',
            value: '',
        },

        documents : [],
        receipt: null,
        mounted: false,
        uploadingReceipt: false,
        uploadingEob: false,
        sendingReceipt: false,

        checkSendAlert: false,
        checkUnflag: false,

        claimForHoverIdx: -1,

        choosingEmails: false,
        reimbursementAmount: "",
        sendEmailsTo: [],

        hoveredImgIdx: -1,
    }

    componentDidMount = async () => {
        this.props.setLoadingState(true);

        // File uploader for receipt
        this.receiptUpload = document.createElement('input');
        this.receiptUpload.setAttribute('type', 'file');

        // File uploader for receipt
        this.eobUpload = document.createElement('input');
        this.eobUpload.setAttribute('type', 'file');

        // login
        await api.post("self/adminLogin").catch(() => {
            this.props.history.push("/login");
            return
        })

        // fetch data
        await this.fetchClaim();
        await this.fetchReceipt();

        await this.setState({dataFields: this.state.claim.fields ? this.state.claim.fields : {}});

        this.props.setLoadingState(false);

        this.fetchDocuments()
    }

    // Grab all the documents from s3
    fetchDocuments = async () => {
        this.setState({mounted: false})
        let documents = [];
        for (let docKey of this.state.claim.eob) {
            const docKeySplit = docKey.split('/');
            let docStream = await api.get("claim/download", { id: this.state.claim._id, key: docKey}, null, "blob")
            const blob = new Blob(
                [docStream],
                {type: docKeySplit && docKeySplit.length >= 2 && docKeySplit[2] === 'pdf' ? 'application/pdf' : "image/png"}
            )

            documents.push(URL.createObjectURL(blob))
        }
        await this.setState({
            documents, mounted: true,
        });
    }

    fetchReceipt = async () => {

        if (!this.state.claim.receipt)
            return

        let docStream = await api.get("claim/download", { id: this.state.claim._id, key: this.state.claim.receipt }, null, "blob").catch(e => console.log(e))
        let blob = new Blob(
            [docStream],
            { type: "application/pdf" }
        )
        let receipt = URL.createObjectURL(blob);
        this.setState({receipt});

    }

    fetchClaim = async () => {
        const pathSplit = this.props.location.pathname.split('/');
        const claimId = pathSplit[pathSplit.length-1]
        const claim = await fetchClaimById(claimId)
        const employee = await fetchEmployeeById(claim.employee);
        const employer = await fetchEmployerById(claim.employer);
        const plan = await fetchPlanById(claim.plan);
        this.setState({claim, employee, employer, plan});
    }

    toggleEditing = async () => {
        console.log('toggling editing', this.state.new)

        this.setState({
            editsMade: false,
            editingFields: {
                claimFor: this.state.claim.for,
            },
            newDataField: {key: '', value: ''},
            dataFields: this.state.claim.fields ? this.state.claim.fields : {},
        });
    }

    saveEdits = async () => {
        this.props.setLoadingState(true);

        if (JSON.stringify(this.state.editingFields.claimFor) !== JSON.stringify(this.state.claim.for)) {
            // save
            await updateClaim(this.state.claim._id, { for: this.state.editingFields.claimFor.filter(x => x.length > 0).map(x => x.trim()) })
        }

        if (this.state.newDataField.key.length > 0 || JSON.stringify(this.state.dataFields) !== JSON.stringify(this.state.claim.fields)) {
            let currentDataFields = {...this.state.dataFields};

            if (this.state.newDataField.key.length > 0)
                currentDataFields[this.state.newDataField.key] = this.state.newDataField.value;

            await updateClaim(this.state.claim._id, {
                fields: currentDataFields,
            });
        }

        await this.fetchClaim();
        this.toggleEditing();
        this.props.setLoadingState(false);
    }

    setEditableFieldValue = (newValue, key) => {
        if (!this.state.editsMade)
            this.setState({editsMade: true});

        let fields = {...this.state.editingFields}
        fields[key] = newValue;
        this.setState({editingFields: fields});
    }

    setDataField = (keyOrValue, keyIdx, newValue) => {
        if (!this.state.editsMade)
            this.setState({editsMade: true});

        const dataFields = {...this.state.dataFields};
        const keys = Object.keys(dataFields);

        let newDataFields = {}
        if (keyOrValue === 'key') {
            for (let i=0; i<keys.length; i++) {
                if (i === keyIdx) {
                    newDataFields[newValue] = dataFields[keys[keyIdx]];
                } else {
                    newDataFields[keys[i]] = dataFields[keys[i]];
                }
            }
            this.setState({ dataFields: newDataFields });
        } else {
            dataFields[keys[keyIdx]] = newValue;
            this.setState({dataFields});
        }
    }

    setClaimFor = async (value, idx) => {
        let editingFields = {
            ...this.state.editingFields,
            claimFor: [...this.state.editingFields.claimFor]
        };

        if (!this.state.editsMade) {
            editingFields.claimFor = [...this.state.claim.for];
            await this.setState({ editsMade: true, editingFields });
        }

        editingFields.claimFor[idx] = value;

        if (editingFields.claimFor[editingFields.claimFor.length-1].length > 0)
            editingFields.claimFor.push('');
        if (editingFields.claimFor[editingFields.claimFor.length - 1].length === 0 && editingFields.claimFor[editingFields.claimFor.length - 2].length === 0)
            editingFields.claimFor.pop();

        await this.setState({editingFields});
    }

    removeClaimFor = async (idx) => {
        let editingFields = { ...this.state.editingFields };

        if (!this.state.editsMade) {
            editingFields.claimFor = [...this.state.claim.for];
            await this.setState({ editsMade: true, editingFields });
        }

        editingFields.claimFor.splice(idx, 1);
        await this.setState({editingFields})
    }

    addField = () => {
        if (this.state.newDataField.key.length === 0)
            return;
        let currentDataFields = {...this.state.dataFields};
        currentDataFields[this.state.newDataField.key] = this.state.newDataField.value;
        this.setState({
            dataFields: currentDataFields,
            newDataField: {key: '', value: ''},
        })
    }

    removeField = async keyIdx => {

        if (!this.state.editsMade)
            this.setState({ editsMade: true });

        const key = Object.keys(this.state.dataFields)[keyIdx]
        if (promptForDelete(key)) {
            this.props.setLoadingState(true);
            let currentDataFields = {...this.state.dataFields};
            delete currentDataFields[key];
            await this.setState({dataFields: currentDataFields});
            this.props.setLoadingState(false);
        }
    }

    startUploadReceipt = async () => {

        this.receiptUpload.click();
        this.receiptUpload.onchange = async e => {
            let file = e.composedPath?.()?.[0]?.files?.[0] || e.path?.[0]?.files?.[0] || e.target.files[0];
            console.log('file', file)
            let formData = new FormData();
            formData.set("id", this.state.claim._id);
            formData.append('files', file);
            this.props.setLoadingState(true);
            await this.setState({ uploadingReceipt: true });
            await api.post("claim/uploadReceipt", formData, false, {}, true).catch(e => console.log(e));
            await this.fetchClaim();
            await this.fetchReceipt();
            await this.setState({ uploadingReceipt: false });
            this.props.setLoadingState(false);
        }
    }

    uploadReceipt = async file => {
        try {
            console.log('file', file)
            let formData = new FormData();
            formData.set("id", this.state.claim._id);
            formData.append('files', file);
            this.props.setLoadingState(true);
            await this.setState({ uploadingReceipt: true });
            await api.post("claim/uploadReceipt", formData, false, {}, true).catch(e => console.log(e));
            await this.fetchClaim();
            await this.fetchReceipt();
            await this.setState({ uploadingReceipt: false });
            this.props.setLoadingState(false);
        } catch (err) {
            window.alert('Error uploading, please try again.  Contact Aptus if the problem persists');
        }
    }

    uploadEob = async (e) => {
        this.eobUpload.click();
        this.eobUpload.onchange = async e => {
            let file = e.composedPath?.()?.[0]?.files?.[0] || e.path?.[0]?.files?.[0] || e.target.files[0];
            let formData = new FormData();
            formData.set("id", this.state.claim._id);
            formData.set("eobType", file.name.split('.')[file.name.split('.').length-1]);
            formData.append('files', file);
            this.props.setLoadingState(true);
            await this.setState({ uploadingEob: true });
            await api.post("claim/uploadEob", formData, false, {}, true).catch(e => {
                window.alert('Error uploading, please try again.  Contact Aptus if the problem persists');
                console.log(e)
            });
            await this.fetchClaim();
            await this.setState({ uploadingEob: false });
            await this.fetchDocuments();
            this.props.setLoadingState(false);
        }
    }

    sendReceipt = async () => {

        this.props.setLoadingState(true);
        this.setState({ sendingReceipt: true, choosingEmails: false });

        const hrEmails = this.state.sendEmailsTo.filter(x => x !== this.state.employee.email);
        let employeeEmail;
        if (this.state.sendEmailsTo.includes(this.state.employee.email))
            employeeEmail = this.state.employee.email;

        console.log('sending', employeeEmail, hrEmails)
        const res = await sendReceipt({
            id: this.state.claim._id,
            employeeEmail,
            employerEmailList: hrEmails,
            receipt: this.state.claim.receipt,
            amount: this.state.reimbursementAmount,
            employeeName: this.state.employee.name,
            processDate: new Date().toLocaleDateString(),
            employerName: this.state.employer.name
        });

        if (res === 'Error')
            alert('Failed to send receipt.  It\'s possible the email recipient does not exist.  Please make sure the email addresses are accurate.  If the problem persists, please contact Aptus Engineering, Inc.');

        await this.fetchClaim();
        this.setState({ sendingReceipt: false, choosingEmails: false, sendEmailsTo: []});

        this.props.setLoadingState(false);
    }

    createDuplicateClaim = async () => {
        this.props.setLoadingState(true);
        let claimResult = await api.post('claim/duplicate', {id: this.state.claim._id})
        let claim = claimResult.claim
        this.props.history.push('/claim/' + claim._id)
        this.props.setLoadingState(false);
        window.location.reload();
    }


    render = () => {

        if (!this.state.claim)
            return null

        let claimFor = this.state.editsMade ? this.state.editingFields.claimFor : this.state.claim ? this.state.claim.for : ''

        if (claimFor.length === 0 || claimFor[claimFor.length-1].length > 0)
            claimFor.push('');

        return (
            <div>

                {this.state.choosingEmails && <div className='choosingEmailsContainer'>
                    <div className='choosingEmailsBox'>
                        <h2 style={{textAlign: 'center'}}>Select who will receive the receipt email</h2>

                        {(!this.state.employer.emails || this.state.employer.emails.length === 0) && <h4 style={{textAlign: 'center', color: 'red'}}>
                            Warning: no HR emails exist yet for this employer.
                        </h4>}

                        <div
                            style={{ left: '20%', position: 'relative', marginBottom: '5px', maxHeight: '50vh', overflowY: 'auto' }}
                        >
                            <div>
                                <div className='choosingEmailsInput'>
                                    <Checkbox
                                        checked={this.state.sendEmailsTo.includes(this.state.employee.email)}
                                        onClick={async () => {
                                            let email = this.state.employee.email;
                                            if (this.state.sendEmailsTo.includes(email))
                                                await this.setState({ sendEmailsTo: this.state.sendEmailsTo.filter(x => x !== email) })
                                            else {
                                                let sendEmailsTo = [...this.state.sendEmailsTo];
                                                sendEmailsTo.push(email)
                                                await this.setState({ sendEmailsTo })
                                            }
                                            console.log(this.state.sendEmailsTo)
                                        }}
                                    />
                                </div>
                                <span>Employee: {this.state.employee.name} ({this.state.employee.email})</span>
                            </div>

                            {(this.state.employer.emails && this.state.employer.emails.length > 0) && this.state.employer.emails?.map(email => {
                                console.log(this.state.employer)
                                return (
                                    <div>
                                        <div className='choosingEmailsInput'>
                                            <Checkbox
                                                checked={this.state.sendEmailsTo.includes(email.email)}
                                                onClick={async () => {
                                                    if (this.state.sendEmailsTo.includes(email.email))
                                                        await this.setState({ sendEmailsTo: this.state.sendEmailsTo.filter(x => x !== email.email) })
                                                    else {
                                                        let sendEmailsTo = [...this.state.sendEmailsTo];
                                                        sendEmailsTo.push(email.email)
                                                        await this.setState({ sendEmailsTo })
                                                    }
                                                    console.log(this.state.sendEmailsTo)
                                                }}
                                            />
                                        </div>
                                        <span>HR Contact: {email.name} ({email.email})</span>
                                    </div>
                                )
                            })}
                        </div>

                        <div
                            className="claimForInput"
                            style={{ position: 'absolute', bottom: '15%', left: '10%', width: '80%' }}
                        >
                            <input
                                placeholder='Type Reimbursement Amount Here'
                                type='text'
                                ref={"reimbursementAmount"}
                                value={this.state.reimbursementAmount}
                                onChange={e => {this.setState({reimbursementAmount: e.target.value})}}
                            />

                        </div>

                        <button
                            style={{ position: 'absolute', bottom: '3%', left: '5%', width: '30%' }}
                            className="viewDownloadBtn claimBtn"
                            onClick={() => this.setState({choosingEmails: false})}
                        >Cancel
                        </button>

                        {this.state.reimbursementAmount !== "" && this.state.sendEmailsTo && this.state.sendEmailsTo.length > 0 && <button
                            className="sendReceiptBtn claimBtn"
                            style={{position: 'absolute', bottom: '3%', right: '5%', width: '30%'}}
                            onClick={async () => this.sendReceipt()}
                        >Send
                        </button>}

                    </div>
                </div>}


                <h3>
                    Manage Claim # {this.state.claim ? this.state.claim.claimNumber : ''}
                </h3>
                <span className='duplicateClaimBtn clickable' onClick={this.createDuplicateClaim}>Duplicate claim</span>

                {this.state.claim && <div>
                    <p className="claim-subheader" >
                        Claim Generated on {this.state.claim.createdDate ? new Date(this.state.claim.createdDate).toLocaleDateString() : '?'}
                    </p>

                </div>}

                {this.state.editsMade && <div className="edit-page-btns">
                    <button
                        className="edit-save-button"
                        onClick={() => this.toggleEditing()}
                    >
                        DISCARD
                    </button>

                    <button
                        className="edit-page-button"
                        onClick={() => this.saveEdits()}
                    >
                        SAVE PAGE
                    </button>
                </div>}

                <div className="claimID-container">
                    <div className="claim-data-container">

                        <div className="claim-input-title">
                            <h2>Employee Name</h2>
                            <input
                                style={{borderBottom: "none", fontWeight: "500", backgroundColor: "rgb(246, 245, 243)"}}
                                type="text"
                                id=""
                                value={this.state.employee ? this.state.employee.name : ''}
                            />
                        </div>

                        <div className="claim-input-title">
                            <h2>Phone Number</h2>
                            <input
                                style={{borderBottom: "none", fontWeight: "500", backgroundColor: "rgb(246, 245, 243)"}}
                                type="text"
                                id=""
                                value={this.state.employee ? this.state.employee.phone : ''}
                            />
                        </div>

                        <div className="claim-input-title">
                            <h2>Employee Email</h2>
                            <input
                                style={{borderBottom: "none", fontWeight: "500", backgroundColor: "rgb(246, 245, 243)"}}
                                type="text"
                                id=""
                                value={this.state.employee ? this.state.employee.email : ''}
                            />
                        </div>

                        <div className="claim-input-title">
                            <h2>Claim is for</h2>
                            {claimFor.map((claimForItem, claimForIdx) => {
                                return (
                                    <div
                                        className='claimForInput'
                                        onMouseEnter={() => this.setState({ claimForHoverIdx: claimForIdx })}
                                        onMouseLeave={() => this.setState({ claimForHoverIdx: -1 })}
                                    >
                                        <input
                                            placeholder='Type Name'
                                            type='text'
                                            ref={'claimForInput_' + claimForIdx}
                                            value={claimForItem}
                                            onChange={e => this.setClaimFor(e.target.value, claimForIdx)}
                                        />
                                        {claimForIdx !== claimFor.length - 1 && (this.state.editsMade || this.state.claimForHoverIdx === claimForIdx) ? <img
                                            alt="delete"
                                            src='/icons/close.svg'
                                            title='Remove Field'
                                            className='claimForRemoveBtn clickable'
                                            onClick={async () => await this.removeClaimFor(claimForIdx)}
                                        /> : ''}
                                    </div>
                                )
                            })}
                        </div>

                    </div>

                    <div className="reimbursement">
                        <div>

                            <h2>Reimbursement</h2>

                            <p>{this.state.claim.receipt && !this.state.claim.receiptSentDate
                                ? "You've uploaded a receipt but not sent it to the employee and employer. You can view and send it using the buttons below."
                                : this.state.claim.receiptSentDate
                                ? "This receipt was sent on " + new Date(this.state.claim.receiptSentDate).toLocaleString().replace(',', ' at') + ". You can view/update the receipt and resend it."
                                : "Once you have completed the reimbursement, please create the receipt PDF and upload it using the button below. Then fill out the fields below for the employees to view in their app."
                            }</p>


                            <DropZone onDrop={file => this.uploadReceipt(file[0])}>
                                {({getRootProps, getInputProps, isDragActive}) => {
                                    return (
                                        <div {...getRootProps({className: 'documentUpload'})} style={isDragActive ? { opacity: 1 } : {}} >

                                            <input style={{height: '200px'}} {...getInputProps()} />

                                            <div className="documentUploadTip" >
                                                Drop files in here to upload...
                                            </div>

                                        </div>)
                                }}
                            </DropZone>

                            <button
                                className="uploadReceipt claimBtn"
                                onClick = {async () => this.startUploadReceipt()}>
                                {this.state.uploadingReceipt ? 'Uploading...' : this.state.claim.receipt ? "Replace" : "Upload"}
                            </button>

                            {this.state.claim.receipt ?
                                <button className="viewDownloadBtn claimBtn" onClick = {() => {
                                        window.open(this.state.receipt, '_blank');
                                    }}>
                                    View
                                </button> : null}

                            {this.state.claim.receipt ?
                                <button
                                    className="sendReceiptBtn claimBtn"
                                    onClick = {async () => {
                                        let sendEmailsTo = this.state.employer.emailSendList && this.state.employer.emailSendList > 0 ? [...this.state.employer.emailSendList] : [];
                                        sendEmailsTo.push(this.state.employee.email);

                                        this.setState({
                                            choosingEmails: true,
                                            sendEmailsTo: sendEmailsTo
                                        })}
                                    }
                                >
                                    {this.state.claim.receiptSentDate ? 'Resend' : 'Send'}
                                </button> : null}

                            <div className='fieldList'>
                                <h2>Data Fields</h2>

                                {this.state.dataFields ? Object.keys(this.state.dataFields).map((key, keyIdx) => {
                                    return (
                                        <div key={keyIdx}
                                            onMouseEnter={(evt) => this.setState({dataFieldsHoverIdx: keyIdx})}
                                            onMouseLeave={(evt) => this.setState({dataFieldsHoverIdx: -1})}
                                        >
                                            <input type='text' className='fieldKey'
                                                value={key}
                                                onChange={e => this.setDataField('key', keyIdx, e.target.value)}
                                            />{' :'}
                                            <input type='text' className='fieldValue'
                                                value={this.state.dataFields[key]}
                                                onChange={e => this.setDataField('value', keyIdx, e.target.value)}
                                            />
                                            {this.state.dataFieldsHoverIdx === keyIdx && <img
                                                alt="delete"
                                                src='/icons/close.svg'
                                                title='Remove Field'
                                                className='fieldRemove clickable'
                                                onClick={async () => await this.removeField(keyIdx)}
                                            />}
                                        </div>
                                    )
                                }) : ''}

                                <div>
                                    <input type='text' className='fieldKey'
                                        value={this.state.newDataField.key}
                                        placeholder="Field (ex. Deductible)"
                                        onChange={e => this.setState({
                                            newDataField: {
                                                ...this.state.newDataField,
                                                key: e.target.value
                                                },
                                            editsMade: true,
                                    })}
                                    />{' :'}
                                    <input type='text' className='fieldValue'
                                        value={this.state.newDataField.value}
                                        placeholder="Value (ex. $430.43)"
                                        onChange={e => this.setState({
                                            newDataField: {
                                                ...this.state.newDataField,
                                                value: e.target.value
                                                },
                                                editsMade: true
                                        })}
                                    />
                                    <div className='fieldAdd clickable' onClick={this.addField}>+</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="alert-employee-container">

                        <h2>Alert Employee</h2>

                        {!this.state.checkSendAlert && !this.state.checkUnflag && <div>
                            <p style={{marginBottom: "0px"}}>{this.state.claim.flagged ? "This employee is flagged. Would you like to unflag them?" : "Would you like the employee to contact you?"}</p>
                            <button
                                onClick={()=> {
                                    if (this.state.claim.flagged) { this.setState({checkUnflag: true}) }
                                    else { this.setState({checkSendAlert: true}) }
                                }}
                                className="alert-button">yes
                            </button>
                        </div>}

                        {this.state.checkSendAlert && <div>
                            <p style={{marginBottom: "0px"}}>Are you sure you want the employee to contact you? This will send them an email and a notification on the app.</p>
                            <button
                                onClick={async ()=> {
                                    this.props.setLoadingState(true);
                                    this.setState({checkSendAlert: false, claim: { ...this.state.claim, flagged: true }})
                                    await api.get("claim/flag", {id: this.state.claim._id} )
                                    await this.fetchClaim()
                                    this.props.setLoadingState(false);
                                }}
                                className="alert-button">yes
                            </button>
                            <button
                                onClick={()=> this.setState({checkSendAlert: false})}
                                className="alert-button">no
                            </button>
                        </div>}

                        {this.state.checkUnflag && <div>
                            <p style={{marginBottom: "0px"}}>Are you sure you want to unflag this employee?</p>
                            <button
                                onClick={async ()=> {
                                    this.props.setLoadingState(true);
                                    this.setState({checkUnflag: false, claim: { ...this.state.claim, flagged: false }})
                                    await api.get("claim/unflag", {id: this.state.claim._id} )
                                    await this.fetchClaim()
                                    this.props.setLoadingState(false);
                                }}
                                className="alert-button">yes
                            </button>
                            <button
                                onClick={()=> this.setState({checkUnflag: false})}
                                className="alert-button">no
                            </button>
                        </div>}

                        {this.state.claim.flagged && <div className="flagged-container">
                                {"Flagged on " + new Date(this.state.claim.flaggedOn).toLocaleString("en-US").replace(',', ' at')}
                        </div>}

                        {this.state.claim.flagged && this.state.claim.flagViewedOn && <div className="viewed-container">
                                {"Flag Viewed on " + new Date(this.state.claim.flagViewedOn).toLocaleString("en-US").replace(',', ' at')}
                        </div>}

                    </div>

                    <PlanCard
                        locked={true}
                        plan={this.state.plan}
                    />
                </div>

                <br />
                <br />

                <button
                    className="uploadEob claimBtn"
                    onClick={async () => this.uploadEob()}
                >{this.state.uploadingEob ? 'Uploading...' : "Upload Doc"}
                </button>

                <button className='unhideBtn claimBtn'
                    onClick={async () => {
                        this.props.setLoadingState(true);
                        await updateClaim(this.state.claim._id, { hiddenEob: [] });
                        this.fetchClaim();
                        this.props.setLoadingState(false);
                    }}
                >Show All</button>

                <br />
                <br />

                {this.state.claim.eob.filter(eob => !this.state.claim.hiddenEob.includes(eob)).map((eob, idx) => {

                    if (!this.state.mounted)
                        return <div className="documents-container" key={idx}><ReactLoading type="spinningBubbles" width="250px" color="rgb(39,66,124)" /></div>

                    else {

                        const eobSplit = eob.split('/')
                        const isPdf = eobSplit.length >= 2 && eobSplit[2] === 'pdf' ? true : false;
                        let newIdx = this.state.claim.eob.map((eobObj, eobIdx) => {return {eobObj, eobIdx}}).filter(x => x.eobObj === eob)[0].eobIdx;
                        let blob = this.state.documents[newIdx];

                        return (
                            <div
                                className="documents-container-img" key={idx}
                                onMouseEnter={() => this.setState({ hoveredImgIdx: idx })}
                                onMouseLeave={() => this.setState({ hoveredImgIdx: -1 })}
                                style={{width: "250px", cursor: "pointer"}}
                            >
                                {!isPdf && <img
                                    // style={{backgroundColor: 'black'}}
                                    alt="preview"
                                    style={{width: "250px", cursor: "pointer"}}
                                    onClick = {() => {window.open(blob)}}
                                    src={blob}
                                />}
                                {isPdf && <Document
                                    file={blob}
                                    alt="preview"
                                    onClick = {() => {window.open(blob)}}
                                    renderMode="canvas"
                                ><Page pageNumber={1} width="250"/></Document>}

                                <div className='hideBtn clickable'
                                    onClick={async () => {
                                        this.props.setLoadingState(true);
                                        await updateClaim(this.state.claim._id, { $push: { hiddenEob: eob }});
                                        this.fetchClaim();
                                        this.props.setLoadingState(false);
                                    }}
                                >{this.state.hoveredImgIdx === idx ? <img src="/icons/close.svg"/> : ''}</div>
                            </div>
                        )
                    }
                })}

                <br />
                <br />

            </div>
        )
    }
};